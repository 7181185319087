<script lang="jsx">
import { Button as SButton } from '@shein/sui-mobile'
import { sui_icon_free_shipping_12px } from '@shein-aidc/icon-vue2'

export default {
  name: 'ProductCardAddBagBtn',
  functional: true,
  emits: ['openQuickAdd', 'openOneClickPayQuickAdd'],
  components: { SButton, sui_icon_free_shipping_12px },
  inject: ['language', 'setAttrForAnalysis'],
  render(h, { injections, listeners }) {
    const { language, setAttrForAnalysis } = injections

    const text = language?.SHEIN_KEY_PWA_29505 || 'One-Click pay'

    const tagUI = () => {
      const tagText = language?.SHEIN_KEY_PWA_29515 || 'Free Shipping'

      return (
        <div class="one-click__tag">
          <sui_icon_free_shipping_12px 
            size="0.32rem"
            color="#fff"
            class="tag__icon"
          />
          <span class="tag__text">{tagText}</span>
        </div>
      )
    }

    
    const handleClick = (e) => {
      listeners.openQuickAdd?.(e)
    }

    setAttrForAnalysis((el) => {
      // 当商品【实际未展示】一键加车按钮，默认不传值。
      // 当商品【实际展示了】一键购加车按钮，上报规则为：固定开头+商品池标签id
      // 固定开头：one_click_pay
      el.setAttribute('data-add-bag-one-click', `one_click_pay`)
    })

    return (

      <div 
        class="one-click-container" 
        v-on:click_stop={handleClick}
      >
        {tagUI()}
        
        <SButton
          type={['primary', 'H56PX']}
          width="100%"
        >
          { text }
        </SButton>
      </div>
    )
  }
}
</script>

<style lang="less">
.one-click-container {
  position: relative;
  padding-top: .213rem;

  .S-button.S-button__primary {
    font-weight: 500;
  }

  .one-click__tag {
    position: absolute;
    right: -0.16rem;
    top: 0;
    z-index: @zindex-hack;

    padding: 0 .106rem;
    height: 0.3733rem;

    .flexbox();
    .align-center();

    background-color: @sui_color_success;

    .tag__icon {
      transform: rotateY(0deg)/* rtl: rotateY(180deg) */;
      transform-origin: center center;
    }

    .tag__text {
      padding-left: .08rem;
      line-height: .3733rem;
      font-size: .213rem;
      color: #fff;
    }

    // 底部三角形阴影
    &::after {
      content: "";
      position: absolute;
      top: 0.3733rem;
      right: 0;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: .16rem .16rem 0 0;
      border-color: #16533A transparent transparent transparent;
    }
  }
}
</style>
